import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  gap: 16px;
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 46px 0 0 0;
  gap: 16px;
  height: 100%;
  padding: 0 0 16px 0;
`;

export const CareerWidgetWrapper = styled.div`
  margin: 16px 0 0 0;
  position: sticky;
  bottom: 16px;
  right: 40px;
  z-index: 10;
  width: calc(100vw - 60px - 224px);
  box-shadow: 0 -5px 16px 0 #00000033;
  border-radius: 16px;
`;
