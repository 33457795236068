import React, { useState, useEffect, useRef } from 'react';

import {
  Competence,
  Completed,
  Top,
  TopBody,
  ArrowContainer,
  CompetenceName,
  CompletedMark,
  CompetenceLevels,
  CompetenceLevel,
  Line,
  Indicators,
  IndicatorsInner,
  Indicator,
  IndicatorHeader,
  IndicatorLevel,
  IndicatorTop,
} from './styles';

import { ComparativeIndicatorLevelState } from '@/widgets';
import { ArrowOpen, Tick } from '@/UI';
import { Margin } from '@mui/icons-material';
import { fontWeight } from '@mui/system';

export interface CompetencesDifferenceCardProps {
  instance: any;
  compareTo: any;
  size: string;
  expanded: boolean;
}

export const CompetencesDifferenceCard: React.FC<CompetencesDifferenceCardProps> = ({ instance, compareTo, size, expanded }) => {
  const [isOpen, setIsOpen] = useState(expanded);
  const [indicatorsHeight, setIndicatorsHeight] = useState({});

  const indicatorsInnerRef = useRef(null);

  const [differenceLevels, setDifferenceLevels] = useState(
        {
            user: '',
            specialization: '',
        }
    );
  const [differenceColor, setDifferenceColor] = useState('');

  const [indicatorLevelPairs, setIndicatorLevelPairs] = useState([]);

  const [isCompleted, setIsCompleted] = useState(false);
  const completedFade: number = 0.4;

  const styles: any = {
    small: {
      competence: {
        width: '100%',
        borderRadius: '10px',
        boxShadow: 'inset 0 0 0 1px #E6E6E6',
        backgroundColor: isCompleted ? '#F6F7F7' : '#FFF',
      },
      completed: {
        gap: '6px',
        padding: '10px 10px 0',
      },
      top: {
        padding: '10px',
        gap: '10px',
        fontSize: '12px',
      },
      topBody: {
        gap: '0px',
      },
      competenceName: {
        fontWeight: '600',
        fontSize: '12px',
      },
      line: {
        margin: '0 0 10px 0',
      },
      indicators: {
        height: isOpen ? String(indicatorsInnerRef.current?.offsetHeight + 10) + 'px' : '0px',
        opacity: isOpen ? (isCompleted ? completedFade : 1) : '0',
        padding: isOpen ? '0 10px 10px 10px' : '0 10px 0 10px',
      },
      indicatorsInner: {
        gap: '10px',
      },
      indicator: {
        gap: '3px',
      },
      indicatorHeader: {
        fontSize: '12px',
        fontWeight: '400',
      },
    },
    large: {
      competence: {
        borderRadius: '16px',
      },
      completed: {
        gap: '8px',
        padding: '16px 16px 0',
      },
      top: {
        padding: '16px',
        fontSize: '16px',
      },
      topBody: {
        gap: '5px',
      },
      competenceName: {
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '18px',
      },
      line: {
        margin: '0 0 16px 0',
      },
      indicators: {
        height: isOpen ? String(indicatorsInnerRef.current?.offsetHeight + 16) + 'px' : '0px',
        opacity: isOpen ? (isCompleted ? 0.5 : 1) : '0',
        padding: isOpen ? '0 25px 16px 16px' : '0 16px 0 16px',
      },
      indicatorsInner: {
        gap: '16px',
      },
      indicator: {
        gap: '5px',
      },
    }
  }

  const level_gradation_map: any = {
    'Beginner': 0,
    'Intermediate': 1,
    'Master': 2,
  }

  const enRusMap: any = {
    'Beginner': 'Слабый',
    'Intermediate': 'Уверенный',
    'Master': 'Сильный',
  };

  function get_competence_level(
    grades: any,
    target_indicators_levels: any,
  ) {    
    const level_gradation_mapping: any = {
      Beginner: 0,
      Intermediate: 1,
      Master: 2,
    };

    try {
      let target_indicators_levels_mapping: any = {};

      for (const target_indicator_level of target_indicators_levels) {
        target_indicators_levels_mapping[
          target_indicator_level.indicator.name
        ] = target_indicator_level.score ? get_level_by_score(
          target_indicator_level.score,
          target_indicator_level.indicator.gradations,
        ) : target_indicator_level.level
      }

      let competence_level: string = 'Beginner';

      const grades_array = [
        ...grades.filter(
          (grade: any) => {
            if (grade.name === 'Beginner') {
              return grade;
            }
          }
        ),
        ...grades.filter(
          (grade: any) => {
            if (grade.name === 'Intermediate') {
              return grade;
            }
          }
        ),
        ...grades.filter(
          (grade: any) => {
            if (grade.name === 'Master') {
              return grade;
            }
          }
        ),
      ];

      for (const grade of grades_array) {
          let indicators_levels_mapping: any = {};

          for (const indicator_level of grade.indicators_levels) {
            indicators_levels_mapping[
                indicator_level.indicator.name
            ] = indicator_level.level;
          }

          let level_flag: boolean = true;

          for (const indicator_name of Object.keys(indicators_levels_mapping)) {
              if (level_gradation_mapping[
                target_indicators_levels_mapping[indicator_name]
                ] < level_gradation_mapping[indicators_levels_mapping[indicator_name]]
              ) {
                level_flag = false;
                break;
              }
          }

          if (!level_flag) {
            break
          }

          competence_level = grade.name;
      }
      
      return competence_level;
    }
    catch(e) {
      console.log(e);
      return '';
    }
  }

  function get_level_by_score(score: number, gradations: any) {
    const level: string = 'Master';

    const gradation_level_mapping: any = {
      0: 'Beginner',
      1: 'Intermediate',
      2: 'Master',
    }

    for (let i = 0; i < gradations.length; i++) {
      if (score < gradations[i]) {
        return gradation_level_mapping[i];
      }
    }

    return level;
  }

  // Function that compares given competences' levels 
  // and sets final difference value
  function defineDifferenceLevel() {
    let instanceLevel: string = '';
    if (instance) {
        instanceLevel = get_competence_level(
            compareTo.competence.grades,
            instance.indicators_levels,
        );
    }

    const compareToLevel: string = get_competence_level(
        compareTo.competence.grades,
        compareTo.indicators_levels,
    );

    if (level_gradation_map[instanceLevel] < level_gradation_map[compareToLevel] || !instanceLevel) {
        setIsCompleted(false);
        setDifferenceLevels(
            {
                user: instanceLevel ? enRusMap[instanceLevel] : 'Нулевой',
                specialization: enRusMap[compareToLevel],
            }
        );
    }
    else if (level_gradation_map[instanceLevel] === level_gradation_map[compareToLevel]) {
        setIsCompleted(true);
        setDifferenceLevels(
            {
                user: instanceLevel ? enRusMap[instanceLevel] : 'Нулевой',
                specialization: enRusMap[compareToLevel],
            }
        );
    }
    else {
        setIsCompleted(true);
        setDifferenceLevels(
            {
                user: instanceLevel ? enRusMap[instanceLevel] : 'Нулевой',
                specialization: enRusMap[compareToLevel],
            }
        );
    }
  }

  function defineIndicatorLevelPairs() {
    let indicatorLevelPairsTemp: any = [];

    for (const compareToIndicatorLevel of compareTo.indicators_levels) {
        if (instance) {
            for (const instanceIndicatorLevel of instance.indicators_levels) {
                if (instanceIndicatorLevel.indicator.name === compareToIndicatorLevel.indicator.name) {
                    indicatorLevelPairsTemp.push(
                        {
                            instance: instanceIndicatorLevel,
                            compareTo: compareToIndicatorLevel,
                        }
                    )
                }
            }
        }
        else {
            indicatorLevelPairsTemp.push(
                {
                    instance: null,
                    compareTo: compareToIndicatorLevel,
                }
            )
        }
    }

    setIndicatorLevelPairs(indicatorLevelPairsTemp);
  }

  useEffect(() => {
    defineDifferenceLevel();
    defineIndicatorLevelPairs();
  }, []);

  useEffect(() => {
    if (size === 'small') {
        setIndicatorsHeight(
            {
                height: isOpen ? String(indicatorsInnerRef.current?.offsetHeight + 10) + 'px' : '0px',
                opacity: isOpen ? '1' : '0',
                padding: isOpen ? '0 10px 10px 10px' : '0 10px 0 10px',
            }
        );
    }
    else {
        setIndicatorsHeight(
            {
                height: isOpen ? String(indicatorsInnerRef.current?.offsetHeight + 16) + 'px' : '0px',
                opacity: isOpen ? '1' : '0',
                padding: isOpen ? '0 16px 16px 16px' : '0 16px 0 16px',
            }
        );
    }
  }, [indicatorsInnerRef.current, isOpen]);

  return (
    <Competence
        style={
            styles[size]['competence']
        }
    >
      {isCompleted && 
        <Completed
            style={
                styles[size]['completed']
            }
        >
            <div>Достигнуто</div>
            <Line
                style={{}}
            />
        </Completed>
      }
      <Top
        onClick={() => setIsOpen(!isOpen)}
        style={styles[size]['top']}
      >
        <TopBody
          style={styles[size]['topBody']}
        >
          <CompetenceName
            style={styles[size]['competenceName']}
          >
            <div>
                {compareTo.competence.name}
            </div>
          </CompetenceName>
          <CompetenceLevels>
            <CompetenceLevel>
                <span
                    style={{
                        color: 'grey',
                    }}
                >
                    Ваш уровень:
                </span>
                <span
                    style={{
                        color: isCompleted ? 'var(--main-purple)' : 'grey',
                    }}
                >
                    {differenceLevels.user}
                </span>
            </CompetenceLevel>
            <CompetenceLevel>
                <span
                    style={{
                        color: 'grey',
                    }}
                >
                    Целевой уровень:
                </span>
                <span
                    style={{
                        color: 'var(--main-purple)',
                    }}
                >
                    {differenceLevels.specialization}
                </span>
            </CompetenceLevel>
          </CompetenceLevels>
        </TopBody>
        {compareTo.indicators_levels &&
          <ArrowContainer
            style={{
              transform: isOpen ? 'rotateX(180deg)' : 'rotateX(0deg)',
            }}
          >
            <ArrowOpen
                color='#000'
            />
          </ArrowContainer>
        }
      </Top>
      {compareTo.indicators_levels && compareTo.indicators_levels.length > 0 && 
        <div
          style={{
            padding: size === 'small' ? '0 10px' : '0 16px',
          }}
        >
          <Line
            style={
              size === 'small' ? 
              {
                margin: isOpen ? '0 0 10px 0' : '0',
                height: isOpen ? '1px' : '0px',
                opacity: isOpen ? '1' : '0',
              }
            :
              {
                margin: isOpen ? '0 0 16px 0' : '0',
                height: isOpen ? '1px' : '0px',
                opacity: isOpen ? '1' : '0',
              }
          }
          />
        </div>
      }
      {indicatorLevelPairs && 
        <Indicators
          style={{
           ...indicatorsHeight,
          }}
        >
          <IndicatorsInner
          style={styles[size]['indicatorsInner']}
            ref={indicatorsInnerRef}
          >
            {indicatorLevelPairs.map((indicatorLevelPair: any) => 
              <Indicator
                key={indicatorLevelPair.compareTo.indicator.name}
                style={styles[size]['indicator']}
              >
                <IndicatorTop>
                  <IndicatorHeader
                    style={styles[size]['indicatorHeader']}
                  >
                    {indicatorLevelPair.compareTo.indicator.name}
                  </IndicatorHeader>
                  <IndicatorLevel
                    style={styles[size]['indicatorLevel']}
                  >
                    {
                      enRusMap[
                        indicatorLevelPair.compareTo.score ? get_level_by_score(
                            indicatorLevelPair.compareTo.score,
                            indicatorLevelPair.compareTo.indicator.gradations,
                        ) : indicatorLevelPair.compareTo.level
                      ]
                    }
                  </IndicatorLevel>
                </IndicatorTop>
                <ComparativeIndicatorLevelState
                  instance={indicatorLevelPair.instance}
                  compareTo={indicatorLevelPair.compareTo}
                  size={size}
                />
              </Indicator>
            )}
          </IndicatorsInner>
        </Indicators>
      }
    </Competence>
  )
}




