import React, { useEffect, useRef, useState } from 'react';
import { useSetState, useMount } from 'react-use';
import { useDisclosure } from '@chakra-ui/react';

import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';

// @ts-ignore
import a11yChecker from 'a11y-checker';

import { Card, IVacanci } from '@/shared';
import { VacanciCard, getCompanyVacancies } from '@/entities/vacanci';
import { EditVacancyPopup } from '@/features/vacanci';

import {
  Container,
  OnboardingContainer,
  Title,
  Line,
  Blue,
} from './styles';

import { EmptyResponse } from '../EmptyResponse/EmptyResponse';
import { getVacancies } from '@/entities/vacanci';
import vacanciesState from '@/entities/vacanci/store/vacanciesState';
import { observer } from 'mobx-react-lite';
import { getCompany } from '@/entities/company/api';


// Onboarding
interface Props {
  breakpoint: string;
}

interface State {
  run: boolean;
  steps: Step[];
}

export const VacanciesList: React.FC = observer(() => {
  // Onboarding
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [firstVacancy, setFirstVacancy] = useState(undefined);
  // const edicVacancyPopupRef = useRef(null);
  
  const [offset, setOffset] = useState(0);
  const [isAwaiting, setIsAwaiting] = useState(false);
  const lastElement = useRef();
  const observer = useRef<IntersectionObserver>();  

  const companyId = JSON.parse(localStorage.getItem('user'))?.company[JSON.parse(localStorage.getItem('user'))?.company.length - 1];
  const company = JSON.parse(localStorage.getItem('company'));

  function handleGetVacancies() {
    getVacancies(offset)
      .then((res) => {
        vacanciesState.setAllVacancies([...vacanciesState.vacancies.all, ...res.data.results]);
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        setIsAwaiting(false)
      })
  }

  useEffect(() => {
    setIsAwaiting(true);
    getCompanyVacancies(companyId)
      .then((res) => {
        const positions: any[] = [];
        res.data.forEach((el: any) => {
          positions.push(el.company_position);
        })
        vacanciesState.setCompanyVacancies(positions);
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        setIsAwaiting(false);
      })
    
    console.log(JSON.parse(JSON.stringify(vacanciesState.vacancies.company)));
    
    if (company.onboarding_mode) {
      if (vacanciesState.vacancies.company.length > 0) {
        setFirstVacancy(vacanciesState.vacancies.company[0]);
      }
    }
  }, [])

  useEffect(() => {
    setIsAwaiting(true);
    handleGetVacancies();
  }, [offset])

  useEffect(() => {
    if (vacanciesState.vacanciesFilter === 'global' && vacanciesState.vacancies.all.length > 50) {
      if (isAwaiting) return;
      if (observer.current) observer.current.disconnect();
      const callback = function (entries: any) {
        if (entries[0].isIntersecting) {
          setOffset(offset + 10);
        }
      }
      observer.current = new IntersectionObserver(callback);
      observer.current.observe(lastElement.current)
    }
  }, [isAwaiting, vacanciesState.vacanciesFilter])

  const onboardingStyles = {
    tooltip: {
      borderRadius: '16px',
      padding: '16px',
    },
    tooltipContainer: {
    },
    tooltipContent: {
      padding: '0',
      textAlign: 'left',
      display: 'flex',
    },
    buttonNext: {
      backgroundColor: 'var(--main-purple)',
      marginTop: '0px',
      padding: '8px 10px',
      fontSize: '1rem',
    },
    buttonSkip: {
      display: 'none',
    },
    buttonBack: {
      padding: 0,
      margin: '0 16px 0 0',
      color: '#000',
      fontSize: '1rem',
    }
  }

  // const { breakpoint } = props;
  const [{ run, steps }, setState] = useSetState<State>({
    run: true,
    steps: [
      {
        content: <OnboardingContainer><Title>Онбординг</Title><Line/><h5>Добро пожаловать!</h5><div>Чтобы ответить на возможные вопросы по работе платформы, просим вас пройтись с нами по ее ключевым возможностям</div><Line/></OnboardingContainer>,
        placement: 'center',
        target: '#vacanciesList',
        locale: {
          nextLabelWithProgress: 'Далее',
          back: 'Назад',
        },
        // @ts-ignore
        styles: {
          ...onboardingStyles,
        }
      },
      {
        content: (
          <OnboardingContainer>
            <Title>
              База должностей
            </Title>
            <Line/>
            <h5>
              На этом экране вы можете видеть раздел 
              <Blue>«База должностей»</Blue>, 
              содержащий должности вашей компании
            </h5>
            <div>
              Сейчас мы для наглядности заполнили его за вас, 
              но после прохождения обучения вы и сами сможете добавлять 
              новые должности или изменять текущие. База должностей - важный раздел, 
              так как именно на ее основе будут строиться карьерные треки для ваших сотрудников
            </div>
            <Line/>
          </OnboardingContainer>
        ),
        placement: 'bottom',
        target: '#vacanciesList',
        locale: {
          nextLabelWithProgress: 'Из чего состоит должность?',
          back: 'Назад',
        },
        // @ts-ignore
        styles: {
          ...onboardingStyles,
        }
      },
      {
        content: (
          <OnboardingContainer>
            <Title>
              Должность
            </Title>
            <Line/>
            <h5>
              Так выглядит <Blue>должность</Blue>
            </h5>
            <div>
              Она характеризуется названием, грейдом и описанием. 
              Чтобы обеспечить четкие требования к сотрудникам, находящимся на этой должности, 
              вы можете указать компетенции, необходимые для выполнения ее рабочих функций
            </div>
            <Line/>
          </OnboardingContainer>
        ),
        spotlightPadding: 7,
        placement: 'auto',
        target: 'body',
        locale: {
          nextLabelWithProgress: 'Далее',
          back: 'Назад',
        },
        // @ts-ignore
        styles: {
          ...onboardingStyles,
        }
      },
      {
        content: 'These are our super awesome projects!',
        placement: 'bottom',

        styles: {
          options: {
            width: 300,
          },
        },
        target: '.demo__projects h2',
        title: 'Our projects',
      },
      {
        content: (
          <div>
            You can render anything!
            <br />
            <h3>Like this H3 title</h3>
          </div>
        ),
        placement: 'top',
        target: '.demo__how-it-works h2',
        title: 'Our Mission',
      },
      {
        content: (
          <div>
            <h3>All about us</h3>
            <svg
              height="50px"
              preserveAspectRatio="xMidYMid"
              viewBox="0 0 96 96"
              width="50px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path
                  d="M83.2922435,72.3864207 C69.5357835,69.2103145 56.7313553,66.4262214 62.9315626,54.7138297 C81.812194,19.0646376 67.93573,0 48.0030634,0 C27.6743835,0 14.1459311,19.796662 33.0745641,54.7138297 C39.4627778,66.4942237 26.1743334,69.2783168 12.7138832,72.3864207 C0.421472164,75.2265157 -0.0385432192,81.3307198 0.0014581185,92.0030767 L0.0174586536,96.0032105 L95.9806678,96.0032105 L95.9966684,92.1270809 C96.04467,81.3747213 95.628656,75.2385161 83.2922435,72.3864207 Z"
                  fill="#000000"
                />
              </g>
            </svg>
          </div>
        ),
        placement: 'left',
        target: '.demo__about h2',
      },
      {
        content: <h2>Let's all folks</h2>,
        placement: 'center',
        target: 'body',
      },
    ],
  });

  useMount(() => {
    a11yChecker();
  });
  
  const handleStart = () => {
    setState({
      run: true,
    });
  }

  const handleClickStart = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    handleStart();
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setState({ run: false });
    }

    if (data.action === 'next' || data.action === 'prev') {
      if (data.type === 'step:after' && data.index === 1) {
        onOpen();
      }
      else {
        onClose();
      }
    }

    // logGroup(type, data);
  };

  return (
    <>
      {company.onboarding_mode && 
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          run={run}
          scrollOffset={64}
          scrollToFirstStep
          showProgress
          showSkipButton
          steps={steps}
          hideCloseButton={true}
          disableCloseOnEsc={true}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
      }
      {vacanciesState.vacanciesFilter === 'global' &&
        <>
          {vacanciesState.vacancies.all.length === 0 &&
            <EmptyResponse/>
          }
          <Container>
            {vacanciesState.vacancies.all && vacanciesState.vacancies.all.map(vacanci =>
              <Card key={vacanci.id}>
                <VacanciCard vacanci={vacanci} key={vacanci.id}/>
              </Card>
            )}
          </Container>
          <div ref={lastElement}></div>
        </>
      }
      {vacanciesState.vacanciesFilter === 'company' &&
        <>
          {vacanciesState.vacancies.company.length === 0 &&
            <EmptyResponse/>
          }
          <Container
            id='vacanciesList'
          >
            {vacanciesState.vacancies.company && vacanciesState.vacancies.company.map(vacanci =>
              <Card key={vacanci.id}>
                <VacanciCard vacanci={vacanci} key={vacanci.id}/>
              </Card>
            )}
          </Container>
        </>
      }
      {vacanciesState.vacancies.company.length > 0 && 
        <EditVacancyPopup
          isOpen={isOpen}
          onClose={onClose}
          vacancy={vacanciesState.vacancies.company[0]}
          // ref={edicVacancyPopupRef}
        />
      }
    </>
  )
});
