import React from 'react';

export interface ArrowOpenProps {
  color: string;
}

export const ArrowOpen: React.FC<ArrowOpenProps> = ({ color='#000' }) => {
  return (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_205_7170)">
        <path d="M1 1L5 5L9 1" stroke="black" strokeLinecap="round"/>
      </g>
      <defs>
        <clipPath id="clip0_205_7170">
          <rect width="10" height="6" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}
