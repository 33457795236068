import React, { useState, useEffect } from 'react';
import { Container, Inputs } from './styles';
import {
  InputGroup,
  InputLeftElement,
  Input,
  Button, 
  useDisclosure
} from '@chakra-ui/react';
import AddIcon from '@/assets/icons/add.svg';
import SearchIcon from '@/assets/icons/search.svg';
import { IVacanci, useDebounce } from '@/shared';
import { searchVacanci, searchCompanyVacanci } from '@/features/vacanci';
import { observer } from 'mobx-react-lite';
import vacanciesState from '@/entities/vacanci/store/vacanciesState';
import { CreateVacancyPopup } from '@/features/vacanci';
import { CrossAdd } from '@/UI';

export const VacanciesTopBar: React.FC = observer(() => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchTerm, setSearchTerm] = useState('');

  const company = JSON.parse(localStorage.getItem('company'));
  
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  function handleSearch() {
    if (vacanciesState.vacanciesFilter === 'global') {
      searchVacanci(debouncedSearchTerm)
        .then((res: any) => {
          if (res?.status === 200) {
            vacanciesState.setAllVacancies(res.data.results);
          }
        })
        .catch((error) => {
          console.log(error);
        })
    }
    if (vacanciesState.vacanciesFilter === 'company') {
      searchCompanyVacanci(debouncedSearchTerm)
        .then((res: any) => {
          if (res?.status === 200) {
            const vacancies: IVacanci[] = [];
            res.data.results.forEach((result: any) => {
              vacancies.push(result.company_position);
            })
            vacanciesState.setCompanyVacancies(vacancies);
          }
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }

  useEffect(() => {
    if (typeof debouncedSearchTerm === 'string') {
      handleSearch();
    }
  }, [debouncedSearchTerm]);

  return (
    <>
      <Container>
        <Inputs>
          <InputGroup padding={'6px'}>
            <InputLeftElement pointerEvents='none'>
              <SearchIcon
                color='var(--addable-gray)'
                width={18}
                height={18}
              />
            </InputLeftElement>
            <Input
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              color='messenger'
              height={'28px'}
              variant='flushed'
              placeholder={company.competence_system ?
                              'Поиск по названию или компетенциям'
                            :
                              'Поиск по названию или навыкам'
                          }
            />
          </InputGroup>
          <Button
          id='addVacancyButton'
            borderRadius='8px'
            bg='var(--main-purple)'
            _hover={{ bg: '#2D53DA' }}
            // leftIcon={<AddIcon color='#fff' />}
            onClick={onOpen}
            padding='15px 10px'
            // leftIcon={<AddIcon color='#fff' />} 
          >
            <CrossAdd
              color='#FFFFFF'
            />
          </Button>
        </Inputs>
      </Container>
      <CreateVacancyPopup isOpen={isOpen} onClose={onClose} />
    </>
  )
});
