import React, { useEffect, useRef, useState } from 'react';
import { CareerSteps } from '@/widgets';
import { Filters } from '@/features/common';
import {
  Container,
  Page,
  CareerWidgetWrapper,
} from './styles';
import { CareerWidget } from '@/widgets';
import { CareerSkills, CareerCompetences } from '@/widgets';
import { getEmployeeById } from '@/features/employee';

export const Career: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>('road');
  const [user, setUser] = useState(null);

  const userId = JSON.parse(localStorage.getItem('user'))?.id;
  const company = JSON.parse(localStorage.getItem('company'));

  const careerWidgetRef = useRef(null);

  useEffect(() => {
    getEmployeeById(userId)
      .then((res) => {
        setUser(res.data)
      })
      .catch((e) => {
        console.log(e);
      })
  }, [])

  const options = [
    {
      id: 1,
      name: 'Карьерный путь',
      onClick: () => setActiveTab('road'),
    },
    company.competence_system ? {
      id: 2,
      name: 'Компетенции',
      onClick: () => setActiveTab('competences'),
    } : {
      id: 2,
      name: 'Навыки',
      onClick: () => setActiveTab('skills'),
    },
  ];

  return (
    <Container>
      <Filters
        options={options}
        style={{
          position: 'fixed',
          // boxShadow: '0 2px 16px 0 #EFEFEF',
          zIndex: '100'
        }}
      />
      <Page>
        {activeTab === 'road' &&
          <CareerSteps/>
        }
        {activeTab === 'skills' &&
        <CareerSkills/>
        }
        {activeTab === 'competences' &&
          <CareerCompetences/>
        }
        {user &&
          <CareerWidgetWrapper
            ref={careerWidgetRef}
          >
            <CareerWidget user={user}/>
          </CareerWidgetWrapper>
        }
      </Page>
    </Container>
  )
}
