import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navbar } from '@/widgets';
import { Outlet } from 'react-router-dom';
import { Page, Board } from './styles';

const skillSystemCategories = [
  {
      id: 1,
      title: 'Личное',
      links: [
          {
              id: 1,
              title: 'Опыт и навыки',
              to: '/dashboard-manager-profile',
          },
          {
              id: 2,
              title: 'Проекты и задачи',
              to: '/dashboard-manager-tasks',
          },
          {
            id: 3,
            title: 'Моя карьера',
            to: '/dashboard-manager-career',
          },
          {
              id: 4,
              title: 'Курсы',
              to: '/dashboard-manager-courses',
          },
          {
              id: 5,
              title: 'Тесты',
              to: '/dashboard-manager-tests',
          },
          {
            id: 6,
            title: 'Аналитика',
            to: '/dashboard-manager-analitic',
          },
      ],
  },
  {
      id: 2,
      title: 'Другое',
      links: [
        {
          id: 6,
          title: 'Ярмарка проектов',
          to: '/dashboard-manager-projects',
        },
        {
          id: 7,
          title: 'Сотрудники',
          to: '/dashboard-manager-employees',
        },
      ],
  },
];

const competenceSystemCategories = [
  {
    id: 1,
    title: 'Профиль развития',
    links: [
      {
        id: 1,
        title: 'Мой профиль',
        to: '/dashboard-manager-profile',
      },
      {
        id: 2,
        title: 'Карьерный трек',
        to: '/dashboard-manager-career',
      },
      {
        id: 3,
        title: 'Обучение',
        to: '/dashboard-manager-courses',
      },
      {
        id: 4,
        title: 'Тесты и опросы',
        to: '/dashboard-manager-tests',
      },
    ],
  },
  {
    id: 2,
    title: 'Открытые возможности',
    links: [
      {
        id: 5,
        title: 'Создать проект или задачу',
        to: '/dashboard-manager-tasks',
      },
      {
        id: 6,
        title: 'Поиск проектов и задач',
        to: '/dashboard-manager-projects',
      },
      {
        id: 7,
        title: 'Аналитика компетенций',
        to: '/dashboard-manager-competence-analytics'
      },
      {
        id: 7,
        title: 'Сотрудники',
        to: '/dashboard-manager-employees',
      },
    ],
  }
];

export const ManagerLayout: React.FC = () => {
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('user'));
  const company = JSON.parse(localStorage.getItem('company'));

  const competence_system_pages: Array<string> = [
    'dashboard-manager-competence-analytics',
  ]
  const competence_system_categories = {
    id: 3,
    title: 'Новое',
    links: [
        {
            id: 6,
            title: 'Аналитика компетенций',
            to: '/dashboard-manager-competence-analytics'
        },
    ]
}

  useEffect(() => {
    if (!user) {
      navigate('/');
    }

    if (user?.role !== 'Manager') {
      navigate('/');
    }
  }, [])

  return (
    <Page>
      <Navbar categories={company.competence_system ? competenceSystemCategories : skillSystemCategories}/>
      <Board>
          <Outlet />
      </Board>
    </Page>
  )
}
