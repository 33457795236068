import styled from "styled-components";

export const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, 296px);
    align-items: flex-start;
    gap: 16px;
    margin: 0 0 16px 0;

    @media screen and (max-width: 1268px) {
        grid-template-columns: repeat(3, 296px);
    }
`;

export const OnboardingContainer = styled.div`
    @import "./fonts.scss";
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-family: "Inter", sans-serif;
    & h5 {
        margin: 5px 0;
    }
    & div {
        font-size: 13px;
    }
`;

export const Title = styled.div`
    font-size: 12px;
    color: grey;
`;

export const Line = styled.div`
    height: 1px;
    width: 100%;
    background-color: #E6E6E6;
    transition: .3s;
`;

export const Blue = styled.span`
    color: var(--main-purple);
`;
