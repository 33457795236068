import styled from "styled-components";

export const GradationBar = styled.div`
    background-color: var(--light-blue);
    width: 100%;
    height: 15px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    overflow: hidden;
    border-radius: 4px;
`;

export const Gradation = styled.div`
    position: relative;
    grid-row: 1;
    background-color: var(--blue);
    height: 100%;
    display: flex;
    align-items: center;
    transition: .3s;
    font-size: 11px;
    border-radius: 4px;
`;
