import React from 'react';

export interface IArrowProgressProps {
  color: string;
  height: string;
}

export const ArrowProgress: React.FC<IArrowProgressProps> = ({ color='#000000', height }) => {
  return (
    <svg width="273" height={height} viewBox={'0 0 273 ' + height} fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="273" height="29" fill="none"/>
        <rect width="701" height="484" transform="translate(-359 -402)" fill="none"/>
        <path d="M272.354 4.35355C272.549 4.15829 272.549 3.84171 272.354 3.64645L269.172 0.464466C268.976 0.269204 268.66 0.269204 268.464 0.464466C268.269 0.659728 268.269 0.976311 268.464 1.17157L271.293 4L268.464 6.82843C268.269 7.02369 268.269 7.34027 268.464 7.53553C268.66 7.7308 268.976 7.7308 269.172 7.53553L272.354 4.35355ZM136.5 29V20H135.5V29H136.5ZM152 4.5H272V3.5H152V4.5ZM136.5 20C136.5 11.4396 143.44 4.5 152 4.5V3.5C142.887 3.5 135.5 10.8873 135.5 20H136.5Z" fill={color}/>
    </svg>

  )
}





