import { makeAutoObservable } from "mobx";

class CareerState {
  activeCareer = 'vacancy';
  stepsByVacancy: any[] = [];
  stepsBySpecialization: any[] = [];
  selectedStepsByVacancy: any[] = [];
  selectedStepsBySpecialization: any[] = [];
  selectedStepsByInterests: any[] = [];
  stepsByInterests: any[] = [];

  specializationCareers: any[] = [];
  interestsCareers: any[] = [];

  skills: any[] = [];
  competences_specializations: any[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setActiveCareer(title: string) {
    this.activeCareer = title;
  }

  setSkills(skills: any) {
    this.skills = skills;
  }
  setCompetencesSpecializations(competences_specializations: any) {
    this.competences_specializations = competences_specializations;
  }

  setStepsByVacancy(steps: any[]) {
    this.stepsByVacancy = steps;
  }

  setStepsByInterests(steps: any[]) {
    this.stepsByInterests = steps;
  }

  setStepsBySpecialization(steps: any[]) {
    this.stepsBySpecialization = steps;
  }

  setSelectedStepsByVacancy(steps: any) {
    this.selectedStepsByVacancy = steps;
  }

  setSelectedStepsBySpecialization(steps: any) {
    this.selectedStepsBySpecialization = steps;
  }

  setSelectedStepsByInterests(steps: any) {
    this.selectedStepsByInterests = steps;
  }

  setSpecializationCareers(careers: any) {
    this.specializationCareers = careers;
  }

  setInterestsCareers(careers: any) {
    this.interestsCareers = careers;
  }
}

export default new CareerState();
