import React, { useState, useEffect } from 'react';

import {
    GradationBar,
    Gradation,
  } from './styles';

export interface ComparativeIndicatorLevelStateProps {
  instance: any;
  compareTo: any;
  size: string;
}

export const ComparativeIndicatorLevelState: React.FC<ComparativeIndicatorLevelStateProps> = (({ instance, compareTo, size }) => {
  const [gridGradation, setGridGradation] = useState<any>({total: 0});
  const [targetGridGradation, setTargetGridGradation] = useState<any>({total: 0});

  function get_level_by_score(score: number, gradations: any) {
    const level: string = 'Master';

    const gradation_level_mapping: any = {
      0: 'Beginner',
      1: 'Intermediate',
      2: 'Master',
    }

    for (let i = 0; i < gradations.length; i++) {
      if (score < gradations[i]) {
        return gradation_level_mapping[i];
      }
    }

    return level;
  }

  useEffect(() => {
    let gridGradationLevelMap: any = {
      Null: 1,
      Beginner: 2,
      Intermediate: 3,
      Master: 4,
    };

    let level = 'Null';
    if (instance) {
        level = instance.score ? get_level_by_score(
            instance.score,
            instance.indicator.gradations,
        ) : instance.level;
    }

    const targetLevel = compareTo.score ? get_level_by_score(
        compareTo.score,
        compareTo.indicator.gradations,
    ) : compareTo.level;

    setGridGradation(gridGradationLevelMap[level]);
    setTargetGridGradation(gridGradationLevelMap[targetLevel]);
  }, []);

  return (
    <GradationBar
      style={{
        height: size === 'small' ? '12px' : '15px',
        borderRadius: size === 'small' ? '3px': '4px',
      }}
    >
        {instance ?
            <Gradation
                style={{
                    gridColumn: '1 / ' + String(gridGradation),
                    backgroundColor: 'var(--blue)',
                    boxShadow: gridGradation <= targetGridGradation ? 'inset 0 0 0 1px var(--main-purple)' : 'none',
                    zIndex: gridGradation <= targetGridGradation ? 1 : 0,
                }}
            />
            :
            <Gradation
                style={{
                    gridColumn: '1 / 2',
                    backgroundColor: 'transparent',
                    boxShadow: 'inset 5px 0 0 0 var(--blue)',
                    zIndex: 0,
                }}
            />
        }        
        <Gradation
            style={{
                gridColumn: '1 / ' + String(targetGridGradation),
                backgroundColor: gridGradation > targetGridGradation ? 'var(--blue)' : 'transparent',
                boxShadow: 'inset 0 0 0 1px var(--main-purple)',
                zIndex: gridGradation > targetGridGradation ? 1 : 0,
            }}
        />
        
        
    </GradationBar>
      
  )
});