import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    Info,
    Container,
    Line,
    ContentInner,
    Absolute,
    Content,
    Top,
    Description,
    Text,
    CollapseButton,
    Skills,
    SkillsWrapper,
    SkillsTitle,
    Competences,
} from './styles';
import CollapseIcon from '@/assets/icons/collapse.svg';
import { CompetencesDifferenceCard, SkillsList } from '@/widgets';
import { ResizeableCompetenceCard } from '@/widgets';
import {
    Card,
    removeExclamation,
} from '@/shared';
import { ArrowOpen } from '@/UI';

export interface StepCardProps {
    step: any;
    index: number;
    initial: boolean;
}

export const StepCard: React.FC<StepCardProps> = ({ step, index, initial=false }) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const company = JSON.parse(localStorage.getItem('company'));

    const [isCardCollapsed, setIsCardCollapsed] = useState(false);
    const [isDescriptionCollapsed, setIsDescriptionCollapsed] = useState(true);

    const [hardSkills, setHardSkills] = useState([]);
    const [softSkills, setSoftSkills] = useState([]);

    const [competencesPairs, setCompetencesPairs] = useState([]);

    const [cardHeight, setCardHeight] = useState(0);
    const [descriptionHeight, setDescriptionHeight] = useState(0);

    const descriptionRef = useRef(null);
    const contentRef = useRef(null);

    useMemo(() => {
        const hardSkillFiltered = step?.skill?.filter((skill: any) => {
            return skill.type === 'Hard'
        })

        setHardSkills(hardSkillFiltered);

        const softSkillFiltered = step?.skill?.filter((skill: any) => {
            return skill.type === 'Soft'
        })

        setSoftSkills(softSkillFiltered);
    }, [step])

    function createCompetencesPairs() {
        let competencesPairsTemp: any = [];
        let match: boolean = false;

        for (const specializationInstance of step.competences) {
            match = false;

            for (const userInstance of user.competences) {

            if (specializationInstance.competence.id === userInstance.competence.id) {
                match = true;

                competencesPairsTemp.push(
                {
                    instance: userInstance,
                    compareTo: specializationInstance,
                }
                );
                
                break;
            }
            }

            if (!match) {
            competencesPairsTemp.push(
                {
                instance: null,
                compareTo: specializationInstance,
                }
            )
            }
        }

        setCompetencesPairs(competencesPairsTemp);
        }

    useEffect(() => {
        // console.log('isCardCollapsed:', isCardCollapsed, 'isDescriptionCollapsed:', isDescriptionCollapsed);
        // console.log('contentRef:', contentRef.current?.offsetHeight, 'descriptionRef:', descriptionRef.current?.offsetHeight);

        if (isDescriptionCollapsed) {
            setDescriptionHeight(64);
            setCardHeight(2000);
        }
        else {
            setDescriptionHeight(descriptionRef.current?.offsetHeight);
            setCardHeight(isCardCollapsed ? 0 : 2000);
        }
    }, [isDescriptionCollapsed]);

    useEffect(() => {
        // console.log('isCardCollapsed:', isCardCollapsed, 'isDescriptionCollapsed:', isDescriptionCollapsed);
        // console.log('contentRef:', contentRef.current?.offsetHeight, 'descriptionRef:', descriptionRef.current?.offsetHeight);

        if (isCardCollapsed) {
            setDescriptionHeight(64);
            setCardHeight(0);
        }
        else {
            setDescriptionHeight(isDescriptionCollapsed ? 64 : descriptionRef.current?.offsetHeight);
            setCardHeight(2000);
        }
    }, [isCardCollapsed]);

    useEffect(() => {
        if (company.competence_system) {
            createCompetencesPairs();
        }
    }, []);

  return (
    <Container>
        <Absolute>
            <Card>
                {initial && 
                    <Info>
                        <div>
                            Текущая должность
                        </div>
                        <Line/>
                    </Info>
                }
                <Content>
                    <Top
                        onClick={() => setIsCardCollapsed(!isCardCollapsed)}
                    >
                        <h5>
                            {index === 0 && <>{step?.grade} </>}{removeExclamation(step?.title)}
                        </h5>
                        <div
                            style={{
                                transform: isCardCollapsed ? '' : 'rotateX(180deg)', transition: 'all .3s ease-in-out'
                            }}
                        >
                            <ArrowOpen
                                color='#000'
                            />
                        </div>
                    </Top>
                    <Content
                        style={{
                            height: 'auto',
                            maxHeight: cardHeight + 'px',
                            opacity: isCardCollapsed ? 0 : 1,
                        }}
                    >
                        <ContentInner
                            ref={contentRef}
                        >
                            <Line
                                style={{
                                    margin: '16px 0 0 0',
                                }}
                            />
                            <Description
                                style={{
                                    maxHeight: descriptionHeight + 'px',
                                }}
                            >
                                <Text
                                    ref={descriptionRef}
                                >
                                    {step?.description}
                                </Text>
                            </Description>
                            {step.description &&
                                <CollapseButton
                                    onClick={() => setIsDescriptionCollapsed(!isDescriptionCollapsed)}
                                >
                                    {isDescriptionCollapsed ? <>Развернуть</> : <>Свернуть</>}
                                    <CollapseIcon
                                        style={{
                                            transform: isDescriptionCollapsed ? '' : 'rotate(180deg)', transition: 'all .2s ease-in-out'
                                        }}
                                        width={10}
                                        height={5}
                                    />
                                </CollapseButton>
                            }

                            {company.competence_system ? 
                                competencesPairs?.length > 0 && 
                                    <Competences>
                                        <Line
                                            style={{
                                                margin: '0 0 8px 0',
                                            }}
                                        />
                                        {competencesPairs.map((pair: any, index: number) => 
                                            <CompetencesDifferenceCard
                                                key={index}
                                                instance={pair.instance}
                                                compareTo={pair.compareTo}
                                                size='small'
                                                expanded={false}
                                            />
                                        )}
                                    </Competences>
                            :
                                <Skills>
                                    <Line/>
                                    <SkillsWrapper>
                                        <SkillsTitle>Hard Skills</SkillsTitle>
                                        <SkillsList skills={hardSkills}/>
                                    </SkillsWrapper>
                                    <SkillsWrapper>
                                        <SkillsTitle>SoftSkills</SkillsTitle>
                                        <SkillsList skills={softSkills}/>
                                    </SkillsWrapper>
                                </Skills>
                            }
                        </ContentInner>
                    </Content>
                </Content>
            </Card>
        </Absolute>
    </Container>
  )
};