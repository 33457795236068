import React, { useEffect, useState } from 'react';
import { StepCard } from '@/entities/step';
import { Container } from './styles';
import { getStepsByVacancy, getStepsBySpecialization, getStepsByInterests } from '@/features/career';
import careerState from '@/features/career/store/careerState';
import { observer } from 'mobx-react-lite';
import { getEmployeeById } from '@/features/employee';
import { ArrowProgress } from '@/UI';

export const CareerSteps: React.FC = observer(() => {
  const [steps, setSteps] = useState([]);
  const [isAwaiting, setIsAwaiting] = useState(false);
  const userId = JSON.parse(localStorage.getItem('user'))?.id;

  useEffect(() => {
    setIsAwaiting(true);
    getEmployeeById(userId)
      .then((res: any) => {
        const steps: any[] = [res.data.specialization];
        const skills: any[] = [];
        const competences_specializations: any[] =  [];

        res.data.specialization.skill.map((skill: any) => {
          skills.push(skill);
        })
        res.data.specialization.competences.map((competence_specialization: any) => {
          competences_specializations.push(competence_specialization);
        })

        res.data.specialization.children.forEach((step: any) => {
          steps.push(step);
          step.skill.forEach((skill: any) => {
            skills.push(skill);
          })
          step.competences.forEach((competence_specialization: any) => {
            competences_specializations.push(competence_specialization);
          })
        })

        steps.push(res.data.active_vacancy);
        setSteps(steps.reverse());

        careerState.setSkills(skills);
        careerState.setCompetencesSpecializations(competences_specializations);
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        setIsAwaiting(false);
      })
  }, [])

  return (
    <Container>
      {isAwaiting && <>Загрузка...</>}
      {steps.map((step: any, index) =>
        <div
          style={{
            width: '296px',
            marginTop: `${(steps.length - index - 1) * 74}px`,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '8px',
          }}
          key={index}
        >
          <ArrowProgress
            color={index < steps.length - 1 ? '#466DF2FF' : '00000000'}
            height={step.title.length > 25 ? '32' : '25'}
          />
          <StepCard step={step} index={index} initial={index === 0}/>
        </div>
      )}
    </Container>
  )
});
