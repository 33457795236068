import React, { useEffect, useState } from 'react';
import { toJS } from 'mobx'

import {
  LevelGradation,
  LevelState,
} from '@/widgets';

import {
  CompetenceRow,
  CompetenceInfo,
  CompetenceHeader,
  CompetenceName,
  CompetenceLevelName,
  LevelPercentage,
  IndicatorRow,
  IndicatorHeader,
  IndicatorName,
  ArrowContainer,
} from './styles';
import { ArrowOpen } from '@/UI';
import competenceAnalyticsState from '@/pages/CompetenceAnalytics/store/competenceAnalyticsState';

export interface CompetenceDropdownProps {
  competence: any,
  isGroup: boolean,
}

export const CompetenceDropdown: React.FC<CompetenceDropdownProps> = ({ competence, isGroup }) => {
  const [isOpen, setIsOpen] = useState<boolean>();
  const [level, setLevel] = useState<string>();

  const triggerDropdown = () => {
    setIsOpen(!isOpen);
  }

  const calculateCompetenceLevel = () => {
    let max_values: any = [];

    const enRusMap: any = {
      'Beginner': 'Слабый',
      'Intermediate': 'Уверенный',
      'Master': 'Сильный',
    };

    for (const levelName of Object.keys(competence.levels)) {
      if (max_values.length > 0) {
        if (competence.levels[levelName] > max_values[0].count) {
          max_values = [{name: levelName, count: competence.levels[levelName]}];
        }
        else if (competence.levels[levelName] === max_values[0].count) {
          max_values.push({name: levelName, count: competence.levels[levelName]});
        }
      }
      else {
        max_values = [{name: levelName, count: competence.levels[levelName]}];
      }
    }
    switch (max_values.length) {
      case 1:
        setLevel(`${enRusMap[max_values[0].name]}`);
      case 2:
        setLevel(`${max_values.map((level: any) => enRusMap[level.name]).join(' / ')}`);
    }
  }

  useEffect(() => {
    calculateCompetenceLevel();
  }, [])

  useEffect(() => {
    calculateCompetenceLevel();
  }, [competenceAnalyticsState.current.competences])

  return (
    <CompetenceRow>
      <CompetenceHeader onClick={triggerDropdown}>
        <ArrowContainer
          style={{ transform: isOpen ? 'rotateX(180deg)' : 'rotateX(0deg)' }}
        >
          <ArrowOpen
            color='#000'
          />
        </ArrowContainer>
        <CompetenceInfo>
          <CompetenceName>
            {competence.name}
          </CompetenceName>
          <CompetenceLevelName>
            {level}
          </CompetenceLevelName>
        </CompetenceInfo>
      </CompetenceHeader>
      {isGroup ? 
        <LevelGradation
          instance={competence}
          indicator={false}
        />
      :
        <LevelState
          instance={competence}
          indicator={false}
        />
      }
      {competence.indicators.map((indicator: any) => 
      <IndicatorRow
        key={indicator.name}
        style={{
          height: isOpen ? '50px' : '0px',
          opacity: isOpen ? '1' : '0',
          zIndex: isOpen ? '1' : '0'
        }}
      >
        <IndicatorHeader
          key={indicator.name}
          // style={{ borderBottom: indicator.id === Math.max(...indicator.indicators.map((obj: any) => obj.indicator.id)) ? '1px solid #E6E6E6' : '0px solid #E6E6E6'}}
        >
        <IndicatorName>
          {indicator.name}
        </IndicatorName>
        </IndicatorHeader>
        {isGroup ? 
          <LevelGradation
            instance={indicator}
            indicator={true}
          />
        :
          <LevelState
            instance={indicator}
            indicator={true}
          />
        }
      </IndicatorRow>
      )}
    </CompetenceRow>
  )
}