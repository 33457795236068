import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button
} from '@chakra-ui/react';
import { Filters } from '@/features/common';
import { observer } from 'mobx-react-lite';
import careerState from '../../store/careerState';
import {
  Section,
  StepsSection,
  StepsSectionTop,
  ListWrapper,
  List,
} from './styles';
import { VacancyVariants } from './VacancyVariants';
import { SpecializationVariants } from './SpecializationVariants';
import { InterestsVariants } from './InterestsVariants';
import { saveCareer } from '../../api';

export interface EditCareerPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

export const EditCareerPopup: React.FC<EditCareerPopupProps> = observer(({ isOpen, onClose }) => {
  const company = JSON.parse(localStorage.getItem('company'));
  const [selected, setSelected] = useState<any>();

  function handleUpdateCareer(e: any) {
    e.preventDefault();
  }

  const options = [
    {
      id: 1,
      name: 'По должности',
      onClick: () => careerState.setActiveCareer('vacancy'),
    },
    company.competence_system ? {
      id: 2,
      name: 'По компетенциям',
      onClick: () => careerState.setActiveCareer('specialization'),
    } : {
      id: 2,
      name: 'По навыкам',
      onClick: () => careerState.setActiveCareer('specialization'),
    },
    {
      id: 3,
      name: 'По интересам',
      onClick: () => careerState.setActiveCareer('interests'),
    },
  ];

  function handleSaveCareer() {
    console.log('before:', JSON.parse(JSON.stringify(careerState)));

    if (careerState.activeCareer === 'vacancy') {
      saveCareer(careerState.selectedStepsByVacancy)
        .then((res: any) => {
          onClose();
          window.location.reload();
        })
        .catch(e => {
          console.log(e);
        })
    }
    if (careerState.activeCareer === 'specialization') {
      saveCareer(careerState.selectedStepsBySpecialization)
        .then((res: any) => {
          onClose();
          window.location.reload();
        })
        .catch(e => {
          console.log(e);
        })
    }
    if (careerState.activeCareer === 'interests') {
      saveCareer(careerState.selectedStepsByInterests)
        .then((res: any) => {
          onClose();
          window.location.reload();
        })
        .catch(e => {
          console.log(e);
        })
    }

    console.log('after:', JSON.parse(JSON.stringify(careerState)));
  }

  function getBorderRadius() {
    switch (careerState.activeCareer) {
      case 'vacancy':
        return careerState.stepsByVacancy.length > 1 ? '16px 16px 4px 4px' : '16px';
      case 'specialization':
        return careerState.stepsBySpecialization.length > 1 ? '16px 16px 4px 4px' : '16px';
      case 'interests':
        return careerState.stepsByInterests.length > 1 ? '16px 16px 4px 4px' : '16px';
      default:
        return '16px';
    }
  }

  return (
    <Modal size={'2xl'} blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={handleUpdateCareer}>
          <ModalContent borderRadius={16}>
            <ModalHeader alignItems={'center'}>
              <h3 style={{ textAlign: 'center' }}>Редактирование карьеры</h3>
            </ModalHeader>
            <ModalCloseButton top={'14px'} />

            <ModalBody
              padding='20px'
              display='flex'
              flexDirection='column'
              gap='20px'
              borderTop='1px solid var(--addable-gray)'
              borderBottom='1px solid var(--addable-gray)'
            >
              <Section>
                {/* <h5>Построение карьерного трека</h5> */}
                <Filters options={options}/>
              </Section>

              <StepsSection
                style={{
                  borderRadius: getBorderRadius(),
                }}
              >
                <StepsSectionTop>
                  <>Направления развития в зависимости от </> 
                  {careerState.activeCareer === 'vacancy' && <>должности</>}
                  {careerState.activeCareer === 'specialization' && 
                    <>
                      {company.competence_system ? 'компетенций' : 'навыков'}
                    </>
                  }
                  {careerState.activeCareer === 'interests' && <>интересов</>}
                </StepsSectionTop>
                <ListWrapper>
                  <List>
                    {careerState.activeCareer === 'vacancy' && 
                      <VacancyVariants/>
                    }
                    {careerState.activeCareer === 'specialization' &&
                      <SpecializationVariants/>
                    }
                    { careerState.activeCareer === 'interests' &&
                      <InterestsVariants/>
                    }
                  </List>
                </ListWrapper>
              </StepsSection>
            </ModalBody>

            <ModalFooter gap='8px' display='flex'>
              <Button
                type='button'
                width='100%'
                onClick={onClose}
                variant='outline'
                color='var(--main-purple)'
                borderRadius='8px'
              >
                Отмена
              </Button>
              <Button
                type='button'
                onClick={handleSaveCareer}
                width='100%'
                borderRadius='8px'
                bg='var(--main-purple)'
                _hover={{ bg: '#2D53DA' }}
                color='#fff'
              >
                Сохранить изменения
              </Button>
            </ModalFooter>
          </ModalContent>
      </form>
    </Modal>
  )
});
