import React, { useEffect, useRef, useState } from 'react';
import { IVacanci } from '@/shared';
import {
    Container,
    Line,
    Top,
    Description,
    Text,
    CollapseButton,
    EditButton,
    EditOptionButton,
    Skills,
    SkillsWrapper,
    SkillsTitle,
    Competences,
} from './styles';
import DotsIcon from '@/assets/icons/dots.svg';
import CollapseIcon from '@/assets/icons/collapse.svg';
import { ResizeableCompetenceCard, SkillsList } from '@/widgets';
import { observer } from 'mobx-react-lite';
import vacanciesState from '../store/vacanciesState';
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
} from '@chakra-ui/react';
import { addVacanciForCompany } from '@/features/company/api';
import { deleteVacancy } from '@/features/vacanci';
import { EditVacancyPopup } from '@/features/vacanci';
import { useDisclosure } from '@chakra-ui/react';
import toast, { Toaster } from 'react-hot-toast';

export interface VacanciCardProps {
    vacanci: IVacanci;
}

export const VacanciCard: React.FC<VacanciCardProps> = observer(({ vacanci }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isCollapsed, setIsCollapsed] = useState(true);
    // const [competencesAreCollapsed, setCompetencesAreCollapsed] = useState(true);
    const companyId = JSON.parse(localStorage.getItem('user')).company[JSON.parse(localStorage.getItem('user')).company.length - 1];
    const company = JSON.parse(localStorage.getItem('company'));

    const descriptionRef = useRef(null);

    function handleAddVacanciForCompany() {
        addVacanciForCompany(vacanci.id, companyId)
            .then((res) => {
                toast.success('Должность успешно добавлена в локальную базу!');
            })
            .catch((e) => {
                console.log(e);
            })
    }

    function handleDeleteVacancy() {
        deleteVacancy(vacanci.id)
            .then((res) => {
                vacanciesState.deleteVacancy(vacanci.id);
            })
            .catch((e) => {
                console.log(e);
            })
    }

    // useEffect(() => {
    //     console.log(JSON.parse(JSON.stringify(vacanci)));
    // }, [])

  return (
    <>
        <Container>
            <Top>
                <h5>{vacanci.grade} {' '} {vacanci.title}</h5>
                <Popover placement='bottom-end'>
                    <PopoverTrigger>
                        <EditButton
                            className='vacancyEditButton'
                        >
                            <DotsIcon
                                color='var(--main-purple)'
                                style={{
                                    margin: '0',
                                }}
                            />
                        </EditButton>
                    </PopoverTrigger>
                    {vacanciesState.vacanciesFilter === 'global' &&
                        <PopoverContent width={'166px'}>
                            <PopoverBody
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    padding: '5px',
                                  }}
                            >
                                <EditOptionButton
                                    style={{ fontSize: '13px' }}
                                    onClick={handleAddVacanciForCompany}
                                >
                                    Добавить в свою базу
                                </EditOptionButton>
                            </PopoverBody>
                        </PopoverContent>
                    }
                    {vacanciesState.vacanciesFilter === 'company' &&
                        <PopoverContent width={'100px'}>
                            <PopoverBody
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    padding: '5px',
                                  }}
                            >
                                <EditOptionButton
                                    style={{ fontSize: '13px' }}
                                    onClick={onOpen}
                                >
                                    Изменить
                                </EditOptionButton>
                                <EditOptionButton
                                    style={{
                                        fontSize: '13px',
                                        color: 'var(--addable-red)'
                                    }}
                                    onClick={handleDeleteVacancy}
                                >
                                    Удалить
                                </EditOptionButton>
                            </PopoverBody>
                        </PopoverContent>
                    }
                </Popover>
            </Top>
            <Line/>
            <Description
                style={{
                    maxHeight: isCollapsed ? '64px' : String(descriptionRef.current.offsetHeight) + 'px',
                    transition: '.3s',
                }}
            >
                <Text
                    ref={descriptionRef}
                >
                    {vacanci?.description}
                </Text>
            </Description>
            {vacanci?.description && 
                <CollapseButton
                    onClick={() => setIsCollapsed(!isCollapsed)}
                >
                    {isCollapsed ? <>Развернуть</> : <>Свернуть</>}
                    <CollapseIcon
                        style={{
                            transform: isCollapsed ? '' : 'rotateX(180deg)', transition: 'all .3s ease-in-out'
                        }}
                        width={10}
                        height={5}
                    />
                </CollapseButton>
            }
            {company.competence_system ? 
                vacanci.competences?.length > 0 && 
                    <Competences>
                        <Line
                            style={{
                                margin: '0 0 8px 0',
                            }}
                        />
                        {vacanci?.competences.map((instance: any, index: number) => 
                            <ResizeableCompetenceCard
                                key={instance.competence.id}
                                instance={instance}
                                size='small'
                                expanded={false}
                            />
                        )}
                    </Competences>
            :
                vacanci.skill?.length > 0 &&
                    <Skills>
                        <Line/>
                        <SkillsWrapper>
                            <SkillsTitle>Hard Skills</SkillsTitle>
                            {vacanci?.hard_skills && <SkillsList skills={vacanci?.hard_skills}/>}
                        </SkillsWrapper>
                        <SkillsWrapper>
                            <SkillsTitle>SoftSkills</SkillsTitle>
                            {vacanci?.soft_skills && <SkillsList skills={vacanci?.soft_skills}/>}
                        </SkillsWrapper>
                    </Skills>
            }

        </Container>
        <EditVacancyPopup
            isOpen={isOpen}
            onClose={onClose}
            vacancy={vacanci}
        />
        <Toaster
            position="top-right"
            toastOptions={{
                style: {
                    boxShadow: '0 0 0 1px #EFEFEF',
                }
            }}
        />
    </>
  )
});
