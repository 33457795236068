import React, { useEffect, useState, useRef } from 'react';
import careerState from '@/features/career/store/careerState';
import { observer } from 'mobx-react-lite';
import { Skill } from '@/shared';
import {
  Container,
  Markers,
  Marker,
  ColorDot,
  InfoText,
  Competences,
  Line,
  Description,
  DescriptionInfoContainer,
  ExpandButton,
  DescriptionInfo,
  InfoPiece,
  DescriptionText,
  CompetencesList,
  CompetencePart,
  Indicator,
  IndicatorInfo,
  GradationBar,
  Gradation,
} from './styles';
import { Link } from 'react-router-dom';
import { ResizeableCompetenceCard } from '@/widgets';
import { CompetencesDifferenceCard } from '@/widgets';
import { ArrowOpen } from '@/UI';

export const CareerCompetences: React.FC = observer(() => {
  const user = JSON.parse(localStorage.getItem('user'));

  const [competencesList, setCompetencesList] = useState(careerState.competences_specializations);
  const [competencesPairs, setCompetencesPairs] = useState([]);

  function get_level_by_score(score: number, gradations: any) {
    if (!score || !gradations) {
      return '';
    }

    const level: string = 'Master';

    const gradation_level_mapping: any = {
      0: 'Beginner',
      1: 'Intermediate',
      2: 'Master',
    }

    for (let i = 0; i < gradations.length; i++) {
      if (score < gradations[i]) {
        return gradation_level_mapping[i];
      }
    }

    return level;
  }

  // Creates pairs of competences: 
  // specializations' competences and user's competences
  function createCompetencesPairs() {
    let competencesPairsTemp: any = [];
    let match: boolean = false;

    for (const specializationInstance of competencesList) {
      match = false;

      for (const userInstance of user.competences) {

        if (specializationInstance.competence.id === userInstance.competence.id) {
          match = true;

          competencesPairsTemp.push(
            {
              instance: userInstance,
              compareTo: specializationInstance,
            }
          );
          
          break;
        }
      }

      if (!match) {
        competencesPairsTemp.push(
          {
            instance: null,
            compareTo: specializationInstance,
          }
        )
      }
    }

    setCompetencesPairs(competencesPairsTemp);
  }

  // Gets the higher-level competence 
  // out of two given
  function get_the_strongest(
    instance: any,
    compareTo: any,
  ) {
    let gradation_level_mapping: any = {
      'Beginner': 0,
      'Intermediate': 1,
      'Master': 2,
    };

    let first_score: number = 0;
    for (const indicator_level of instance.indicators_levels) {
      let level: string = indicator_level.level;
      if (!level) {
        level = get_level_by_score(
          indicator_level.score,
          indicator_level.indicator.gradations,
        )
      }

      first_score += gradation_level_mapping[level];
    }

    let second_score: number = 0;
    for (const indicator_level of compareTo.indicators_levels) {
      let level: string = indicator_level.level;
      if (!level) {
        level = get_level_by_score(
          indicator_level.score,
          indicator_level.indicator.gradations,
        )
      }

      second_score += gradation_level_mapping[level];
    }

    if (second_score > first_score) {
      return compareTo;
    }

    return instance;
  }

  // Filters out all similar competences 
  // getting the strongests out of them
  function filterSpecializationCompetences() {
    // let competences: any = [];

    // for (const instance of careerState.competences_specializations) {
    //   if (
    //     instance.competence?.id in competences.map(
    //       (instance: any) => {return instance.competence?.id}
    //     )
    //   ) {
    //     if (get_the_strongest(instance, '') === )
    //   }
    // }
  }

  useEffect(() => {
    filterSpecializationCompetences();
    createCompetencesPairs();
  }, [])
  
  return (
    <Container>
			{/* {careerState.competences_specializations.length > 0 ? careerState.competences_specializations : 'adfsv'} */}
      <Competences>
        <h3>
          Разница по компетенциям
        </h3>
        <Description>
          <DescriptionText>
            Виджет, отражающий разницу в уровнях владения вашими текущими компетенциями 
            и компетенциями на вашем карьерном пути. Позволяет примерно оценить 
            степень готовности к вступлению на следующую должность карьерного трека.
          </DescriptionText>
          <DescriptionInfo>
            <InfoPiece>
              <GradationBar>
                <Gradation
                  style={{
                    gridColumn: '1 / 4',
                    backgroundColor: 'transparent',
                    boxShadow: 'inset 0 0 0 1px var(--main-purple)',
                    zIndex: 1,
                  }}
                />
              </GradationBar>
              <div>
                Разметка с обводкой указывает на необходимый уровень владения 
                каждым индикатором для освоения компетенции на нужном уровне
              </div>
            </InfoPiece>
            <InfoPiece>
              <GradationBar>
                <Gradation
                  style={{
                    gridColumn: '1 / 4',
                    backgroundColor: 'var(--blue)',
                    // boxShadow: 'inset 0 0 0 1px var(--main-purple)',
                    zIndex: 1,
                  }}
                />
              </GradationBar>
              <div>
                Разметка синего цвета - ваш текущий уровень владения индикатором
              </div>
            </InfoPiece>
            <InfoPiece>
              <GradationBar>
                <Gradation
                  style={{
                    gridColumn: '1 / 2',
                    backgroundColor: 'var(--blue)',
                    // boxShadow: 'inset 0 0 0 1px var(--main-purple)',
                    zIndex: 0,
                  }}
                />
                <Gradation
                  style={{
                    gridColumn: '1 / 3',
                    backgroundColor: 'transparent',
                    boxShadow: 'inset 0 0 0 1px var(--main-purple)',
                    zIndex: 1,
                  }}
                />
              </GradationBar>
              <div>
                При наложении друг на друга они образуют диаграму, отражающую прогресс освоения индикатора. 
                В представленном случае человек владеет индикатором на меньшем, чем необходимо, уровне
              </div>
            </InfoPiece>
          </DescriptionInfo>
        </Description>
        
        {competencesPairs.length > 0 && <Line/>}
        {/* <Markers>
          <Marker>
            <ColorDot
              style={{
                backgroundColor: 'var(--blue)',
              }}
            />
            <InfoText>
              Текущий уровень
            </InfoText>
          </Marker>
          <Marker>
            <ColorDot
              style={{
                backgroundColor: 'var(--green)',
              }}
            />
            <InfoText>
              Превышение уровня
            </InfoText>
          </Marker>
          <Marker>
            <ColorDot
              style={{
                backgroundColor: 'var(--red)',
              }}
            />
            <InfoText>
              Недостаток уровня
            </InfoText>
          </Marker>
        </Markers> */}
        {competencesPairs.length > 0 &&
          <CompetencesList>
            {competencesPairs.map((pair: any, index: number) => 
              <CompetencesDifferenceCard
                instance={pair.instance}
                compareTo={pair.compareTo}
                size='large'
                expanded={true}
                key={index}
              />
            )}
          </CompetencesList>
        }
      </Competences>
      <Competences>
        <h3>
          Компетенции по специализации
        </h3>
        <CompetencesList>
          {careerState.competences_specializations.length > 0 && careerState.competences_specializations.map((instance: any, index: number) => 
            <ResizeableCompetenceCard
              instance={instance}
              size='large'
              expanded={true}
              key={index}
            />
          )}
        </CompetencesList>
      </Competences>
      <Competences>
        <h3>
          Ваши компетенции
        </h3>
        <CompetencesList>
          {user.competences && user.competences.map((instance: any, index: number) => 
            <ResizeableCompetenceCard
              instance={instance}
              size='large'
              expanded={true}
              key={index}
            />
          )}
        </CompetencesList>
      </Competences>
      
    </Container>
  )
});
