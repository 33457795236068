import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  margin-top: -50px;
  gap: 16px;
  width: 100%;
  min-height: calc(100vh - 60px - 28px - 16px);
  overflow: hidden;
  overflow: auto; 
`;
