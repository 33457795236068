import React, { useEffect, useState } from 'react';
import { Top, Inputs, Bottom } from '@/app/layouts/Auth/styles';
import { ButtonSolid } from '@/UI';
import { Link } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
} from '@chakra-ui/react';
import { login } from '@/entities/user';
import { useNavigate } from 'react-router-dom';
import { getCompanyAfterLogin } from '@/entities/company/api';

interface IFormFileds {
  email: string;
  password: string;
}

export const SignIn: React.FC = () => { 
  const { 
    register,
    handleSubmit,
    formState: { errors, isSubmitting } 
  } = useForm<IFormFileds>();
  const navigate = useNavigate();

  const [isProcessing, setIsProcessing] = useState(false);

  const onSubmit: SubmitHandler<IFormFileds> = async (data) => {
    setIsProcessing(true);

    await login(data)
    .then(async (res: any) => {
      if (res?.status === 200) {
        localStorage.clear();
        localStorage.setItem('user', JSON.stringify(res.data.user));
        localStorage.setItem('accessToken', JSON.stringify(res.data.access));
        localStorage.setItem('refreshToken', JSON.stringify(res.data.refresh));

        const companyId = res.data.user.company[res.data.user.company.length - 1];

        await getCompanyAfterLogin(companyId, res.data.access)
          .then((response) => {
            localStorage.setItem('company', JSON.stringify(response.data));
            if (response.data.onboarding_mode) {
              localStorage.setItem(
                'onboarding_progress',
                JSON.stringify(
                  {
                    step: 0,
                    pages: {
                      0: '/dashboard-hr-vacancies',
                      1: '/dashboard-hr-vacancies',
                      2: '/dashboard-hr-competences',
                      3: '/dashboard-hr-competences',
                      4: '/dashboard-hr-competence-analytics',
                      5: '/dashboard-hr-profile?search=',
                      6: '/dashboard-hr-profile?search=',
                      7: '/dashboard-employee-career',
                      8: '/dashboard-employee-courses',
                    },
                  }
                )
              );
            }
          })
          .catch((error) => {
            console.log(error);
          })
        
        setIsProcessing(false);

        location.reload();
        navigate('/dashboard-admin-companies');
      }
    })
    .catch((error) => {
      console.log(error);
    })
  }

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const company = JSON.parse(localStorage.getItem('company'));
    const onboarding_progress = JSON.parse(localStorage.getItem('onboarding_progress'));

    console.log(user);
    console.log(company);
    console.log(onboarding_progress);

    if (user?.role === 'Admin') {
      navigate('/dashboard-admin-companies');
    }

    if (user?.role === 'HR manager') {
      if (company?.onboarding_mode) {
        navigate(onboarding_progress?.pages[onboarding_progress.step]);
      }
      else {
        navigate('/dashboard-hr-employees');
      }
    }

    if (user?.role === 'Super HR manager') {
      if (company?.onboarding_mode) {
        navigate(onboarding_progress?.pages[onboarding_progress.step]);
      }
      else {
        navigate('/dashboard-hr-employees');
      }
    }

    if (user?.role === 'User') {
      navigate('/dashboard-employee-profile');
    }
    
    if (user?.role === 'Manager') {
      navigate('/dashboard-manager-profile');
    }
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Top><h3>Вход</h3></Top>
      <Inputs>
        <FormControl isInvalid={Boolean(errors.email)}>
          <FormLabel>E-mail *</FormLabel>
          <Input
            id='email'
            {...register('email', { 
              required: 'Обязательное поле'
            })}
            placeholder='example.email@gmail.com'
            type='email'
            size='sm'
            variant='flushed'
          />
          <FormErrorMessage>{errors.email && <>{errors.email.message}</>}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(errors.password)}>
          <FormLabel>Пароль *</FormLabel>
          <Input
            id='password'
            {...register('password', { 
              required: 'Обязательное поле'
            })}
            placeholder='••••••••'
            type='password'
            size='sm'
            variant='flushed'
          />
          <FormErrorMessage>{errors.password && <>{errors.password.message}</>}</FormErrorMessage>
        </FormControl>
        <Link to="/RecoveryPassword">Не помню пароль</Link>
      </Inputs>
      <Bottom>
        <ButtonSolid
          type='submit'
          disabled={isProcessing ? true : false}
        >
          {isProcessing ? 'Вход...' : 'Войти'}
        </ButtonSolid>
      </Bottom>
    </form>
  )
}
