import axios from 'axios';

const API = process.env.API_URL;
const access = JSON.parse(localStorage.getItem('accessToken'));
const refresh = JSON.parse(localStorage.getItem('refreshToken'));

export const getStepsByVacancy = async () => {
  const res = await axios<any>({
    url: `${API}/specializations/by_vacancy/`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
  })
    
  return res
} 

export const getStepsBySpecialization = async () => {
  const res = await axios<any>({
    url: `${API}/specializations/by_skills/`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
  })
    
  return res
} 

export const getStepsByInterests = async () => {
  const res = await axios<any>({
    url: `${API}/specializations/by_interests/`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
  })
    
  return res
}  

export const saveCareer = async (steps: any) => {
  console.log(JSON.parse(JSON.stringify(steps)));
  const res = await axios<any>({
    url: `${API}/specializations/`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
    data: steps
  })
    
  return res
}

export const setCareerDeadline = async (userId: number, date: string) => {
  const res = await axios<any>({
    url: `${API}/users/${userId}/`,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
    data: {
      career_tree_deadline: date
    }
  })
    
  return res
} 

