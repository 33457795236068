import styled from "styled-components";

export const Competence = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    position: relative;
    z-index: 0;
    transition: .3s;
`;

export const Completed = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: grey;
`;

export const Top = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    font-size: 16px;
    cursor: pointer;
`;

export const TopBody = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ArrowContainer = styled.div`
    transition: .3s;
`;

export const CompetenceName = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const CompletedMark = styled.div`
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--faded-green);
    border-radius: 10px;
`;

export const CompetenceLevels = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 12px;
`;

export const CompetenceLevel = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;

export const Line = styled.div`
    height: 1px;
    width: 100%;
    background-color: #E6E6E6;
    transition: .3s;
`;

export const Indicators = styled.div`
    padding: 0 16px 16px 16px;
    transition: .3s;
`;

export const IndicatorsInner = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Indicator = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const IndicatorDot = styled.div`
    width: 7px;
    height: 7px;
    border-radius: 10px;
    background-color: #D9DFF4;
`;

export const IndicatorHeader = styled.div`
    font-size: 13px;
    font-weight: 600;
`;

export const IndicatorLevel = styled.div`
    font-size: 12px;
    color: gray;
`;

export const IndicatorTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;