import React from 'react';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Checkbox,
  CheckboxGroup,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { createCompany } from '@/features/company';
import companiesState from '@/entities/company';
import { getCompanies } from '@/entities/company';
import toast, { Toaster } from 'react-hot-toast';

interface IFormFileds {
    name: string;
    subscription_terms: string;
    INN: string;
    OGRN: string;
    city: string;
    competence_system: boolean;
    demo_access: boolean;
    onboarding_mode: boolean;
}

export interface AddCompanyPopupProps {
    isOpen: boolean;
    onClose: () => void;
}

export const AddCompanyPopup: React.FC<AddCompanyPopupProps> = ({ isOpen, onClose }) => {
    const { 
        register,
        handleSubmit,
        formState: { errors, isSubmitting } 
      } = useForm<IFormFileds>();

      function handleGetCompanies() {
        getCompanies()
        .then((res: any) => {
          if (res?.status === 200) {
            companiesState.setCompanies(res.data);
          }
        })
        .catch((error) => {
          console.log(error);
        })
      }
    
      const onSubmit: SubmitHandler<IFormFileds> = (data) => {
        createCompany(data)
        .then((res: any) => {
          if (res?.status === 201) {
            handleGetCompanies();
            onClose();
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.OGRN[0]);
          toast.error(error?.response?.data?.INN[0]);
        })
      }

  return (
    <Modal size={'xl'} blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
            <ModalContent borderRadius={16}>
              <ModalHeader alignItems={'center'}>
                <h3 style={{ textAlign: 'center' }}>Новая компания</h3>
              </ModalHeader>
              <ModalCloseButton top={'14px'} />
              <ModalBody
                padding='20px'
                display='flex'
                flexDirection='column'
                gap='30px'
                borderTop='1px solid var(--addable-gray)'
                borderBottom='1px solid var(--addable-gray)'
              >
                <FormControl isInvalid={Boolean(errors.name)}>
                  <FormLabel>Название компании *</FormLabel>
                  <Input
                    id='name'
                    {...register('name', { 
                      required: 'Обязательное поле'
                    })}
                    placeholder='Компания 5'
                    type='text'
                    size='sm'
                    variant='flushed'
                  />
                  <FormErrorMessage>{errors.name && <>{errors.name.message}</>}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={Boolean(errors.subscription_terms)}>
                  <FormLabel>Условия подписки *</FormLabel>
                  <Input
                    id='subscription_terms'
                    {...register('subscription_terms', { 
                      required: 'Обязательное поле'
                    })}
                    placeholder='Опишите условия подписки'
                    type='text'
                    size='sm'
                    variant='flushed'
                  />
                  <FormErrorMessage>{errors.subscription_terms && <>{errors.subscription_terms.message}</>}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={Boolean(errors.INN)}>
                  <FormLabel>ИНН *</FormLabel>
                  <Input
                    id='INN'
                    {...register('INN', { 
                      required: 'Обязательное поле'
                    })}
                    placeholder='000-000-000-0'
                    type='text'
                    size='sm'
                    variant='flushed'
                  />
                  <FormErrorMessage>{errors.INN && <>{errors.INN.message}</>}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={Boolean(errors.OGRN)}>
                  <FormLabel>ОГРН *</FormLabel>
                  <Input
                    id='OGRN'
                    {...register('OGRN', { 
                      required: 'Обязательное поле'
                    })}
                    placeholder='000-000-000-000-0'
                    type='text'
                    size='sm'
                    variant='flushed'
                  />
                  <FormErrorMessage>{errors.OGRN && <>{errors.OGRN.message}</>}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={Boolean(errors.city)}>
                  <FormLabel>Город *</FormLabel>
                  <Input
                    id='city'
                    {...register('city', { 
                      required: 'Обязательное поле'
                    })}
                    placeholder='Москва'
                    type='text'
                    size='sm'
                    variant='flushed'
                  />
                  <FormErrorMessage>{errors.city && <>{errors.city.message}</>}</FormErrorMessage>
                </FormControl>
                <FormControl>
                  <FormLabel>Использует систему компетенций</FormLabel>
                  <CheckboxGroup>
                    <Checkbox
                      id='competence_system'
                      {...register('competence_system', {})}
                    />
                  </CheckboxGroup>
                </FormControl>
                <FormControl>
                  <FormLabel>Демо доступ</FormLabel>
                  <CheckboxGroup>
                    <Checkbox
                      id='demo_access'
                      {...register('demo_access', {})}
                    />
                  </CheckboxGroup>
                </FormControl>
                <FormControl>
                  <FormLabel>Режим онбординга</FormLabel>
                  <CheckboxGroup>
                    <Checkbox
                      id='onboarding_mode'
                      {...register('onboarding_mode', {})}
                    />
                  </CheckboxGroup>
                </FormControl>
              </ModalBody>

              <ModalFooter gap='8px' display='flex'>
                <Button
                  type='button'
                  width='100%'
                  onClick={onClose}
                  variant='outline'
                  color='var(--main-purple)'
                  borderRadius='8px'
                >
                  Отмена
                </Button>
                <Button
                  type='submit'
                  width='100%'
                  borderRadius='8px'
                  bg='var(--main-purple)'
                  _hover={{ bg: '#2D53DA' }}
                  color='#fff'
                  isLoading={isSubmitting ? true : false}
                >
                  Создать компанию
                </Button>
              </ModalFooter>
            </ModalContent>
        </form>
        <Toaster
          position="top-right"
          toastOptions={{
            style: {
                boxShadow: '0 0 0 1px #EFEFEF',
            }
          }}
        />
    </Modal>
  )
}
