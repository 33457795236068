import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const Markers = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 16px;
    gap: 32px;
    background-color: #fff;
    border-radius: 16px;
`;

export const Marker = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const ColorDot = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 4px;
`;

export const InfoText = styled.div`
    font-size: 12px;
`;

export const Competences = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;
    border-radius: 16px;
    box-shadow: inset 0 0 0 1px #E6E6E6;
    background-color: #F6F7F7;
    transition: .3s;
`;

export const Line = styled.div`
    height: 1px;
    width: 100%;
    background-color: #E6E6E6;
    transition: .3s;
`;

export const Description = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
`;

export const DescriptionText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-size: 13px;
    padding: 16px;
    background-color: #FFF;
    border-radius: 16px;
`;

export const DescriptionInfoContainer = styled.div`
    
`;

export const DescriptionInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    background-color: #FFF;
    border-radius: 16px;
    transition: .3s;
`;

export const InfoPiece = styled.div`
    display: flex;
    gap: 16px;
    font-size: 13px;
`;

export const ExpandButton = styled.div`
    position: sticky;
    width: 100%;
    cursor: pointer;
    transition: .3s;
`;

export const CompetencesList = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, 296px);
    align-items: flex-start;
    gap: 8px;
`;

export const CompetencePart = styled.div`
    max-width: 264px;
    padding: 16px;
    border-radius: 16px;
    background-color: #fff;
`;

export const Indicator = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const IndicatorInfo = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

export const GradationBar = styled.div`
    display: grid;
    min-width: 100px;
    max-width: 100px;
    height: 15px;
    margin: 5px 0 0 0;
    background-color: var(--light-blue);
    grid-template-columns: repeat(3, 1fr);
    overflow: hidden;
    border-radius: 4px;
`;

export const Gradation = styled.div`
    position: relative;
    grid-row: 1;
    background-color: var(--blue);
    height: 100%;
    display: flex;
    align-items: center;
    transition: .3s;
    font-size: 11px;
    border-radius: 4px;
`;
