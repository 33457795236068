import React, { useEffect } from 'react';

import {
  VacanciesTopBar,
  VacanciesList,
} from '@/widgets';

import { ListWrapper } from './styles';
import { Filters } from '@/features/common';
import vacanciesState from '@/entities/vacanci/store/vacanciesState';

export const Vacancies: React.FC = () => {
  // If onboarding mode is true, filters are disabled, 
  // user is only given its local vacancies

  const company = JSON.parse(localStorage.getItem('company'));
  
  // Checks whether to change vacancies db to local or not
  function onboardingModeSettings() {
    if (company.onboarding_mode) {
      vacanciesState.setFilter('company');
    }
  }

  // Options for Filters component
  const options = [
    {
      id: 1,
      name: 'Глобальная база должностей',
      onClick: () => vacanciesState.setFilter('global'),
    },
    {
      id: 2,
      name: 'Должности компании',
      onClick: () => vacanciesState.setFilter('company'),
    },
  ];

  useEffect(() => {
    onboardingModeSettings();
  }, [])

  return (
    <>
      <VacanciesTopBar/>
      {!company.onboarding_mode && 
        <Filters options={options}/>
      }
      <ListWrapper>
        <VacanciesList/>
      </ListWrapper>
    </>
  )
}
